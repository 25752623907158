import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { removeStorage } from '@/common/utils';
import store from '@/store';
import { OrgManageService, LoginService } from '@/sevices';
import { namespace } from 'vuex-class';
import { NodeType } from '../../../typings/organization/org-manage';
import Perm from '@/router/permission';
const workPlatformModule = namespace('workPlatform');
let Setting = class Setting extends Vue {
    constructor() {
        super(...arguments);
        this.visibleUserInfo = false;
        this.isShowSetting = false;
        this.showModifyPassword = false;
        this.NodeType = NodeType;
        /**
         * 组织列表
         */
        this.orgOptions = [];
        /**
         * 当前选择的组织
         */
        this.curOrg = '';
        this.currentDptId = '';
        this.defaultKey = ['0'];
    }
    get userInfo() {
        let publicUserInfo = localStorage.getItem('publicUserInfo');
        if (publicUserInfo) {
            publicUserInfo = JSON.parse(publicUserInfo) || {};
            const userInfo = publicUserInfo || {};
            return userInfo;
        }
        return {
            name: '-',
            mobile: '-',
        };
    }
    get curOrgName() {
        const data = this.orgOptions.find(item => item.value === this.curOrg);
        if (!this.currentDptId)
            return data?.name;
        const json = data.departments.find(item => item.id === this.currentDptId);
        return json?.name;
    }
    get curFullName() {
        const data = this.orgOptions.find(item => item.value === this.curOrg);
        if (!this.currentDptId)
            return data?.deptFullPah || data?.name;
        const json = data.departments.find(item => item.id === this.currentDptId);
        return json?.deptFullPah || json?.name;
    }
    get isShowOrg() {
        return this.sourcePageType === 'workPlatform' && !Perm.isSuperAdmin();
    }
    /**
     * 获取组织列表
     */
    async getOrgList() {
        const { data = [] } = await OrgManageService.getOrgList();
        if (Array.isArray(data)) {
            this.orgOptions = data.map(({ id, name, departments }) => ({
                name: name,
                value: id,
                departments: departments,
            }));
        }
        else {
            this.orgOptions = [];
        }
        if (data) {
            if (data.length === 0)
                return;
            this.curOrg = data[0].id;
            if (data[0].departments && data[0].departments.length > 0) {
                this.currentDptId = data[0].departments[0].id;
                const key = localStorage.getItem('default_key') || '0-0';
                this.defaultKey = [key];
                const curOrgIndex = key.split('-')[0];
                const currentDptIndex = key.split('-')[1];
                this.curOrg = data[curOrgIndex].id;
                this.currentDptId = currentDptIndex ? data[curOrgIndex].departments[currentDptIndex].id : '';
            }
            const corpInfo = localStorage.getItem('corpInfo');
            if (corpInfo) {
                this.updateIsSetOrg(true);
            }
            else {
                this.setOrg(this.curOrg, this.currentDptId);
            }
        }
        else {
            this.curOrg = '';
        }
    }
    /**
     * 设置组织
     */
    async setOrg(id, currentDptId) {
        const param = { orgId: id, currentDptId };
        const { success, data = {}, errMessage = '组织切换失败！', } = await OrgManageService.setOrg(param);
        if (success) {
            if (this.sourcePageType === 'workPlatform') {
                this.$message.success('组织切换成功！');
            }
            // 流程组需要用到
            localStorage.setItem('corpInfo', JSON.stringify(data));
            this.updateIsSetOrg(true);
            this.getLoginInfo();
        }
        else {
            this.$message.warning(errMessage);
        }
    }
    async getLoginInfo() {
        const res = await OrgManageService.getLoginInfo();
        if (res.success) {
            const data = res.data || {};
            const user = {
                userInfo: { id: data.id, name: data.name, username: data.username, type: 3 },
                departmentInfo: { id: data?.mainDepartmentId, name: data?.mainDepartmentName, type: 1 },
            };
            // 运行态的选人控件默认值需要用到
            localStorage.setItem('user', JSON.stringify(user));
        }
    }
    /**
     * 组织切换
     */
    orgChange(corpId, currentDptId, defaultKey) {
        this.defaultKey = [defaultKey];
        localStorage.setItem('default_key', defaultKey);
        this.curOrg = corpId;
        this.currentDptId = currentDptId;
        this.updateIsSetOrg(false);
        this.setOrg(this.curOrg, this.currentDptId);
        this.isShowSetting = false;
    }
    created() {
        if (!Perm.isSuperAdmin()) {
            this.getOrgList();
        }
    }
    logout() {
        this.visibleUserInfo = false;
        this.isShowSetting = false;
        this.$confirm({
            title: '您确认要退出登录吗？',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                removeStorage(store);
                const res = await LoginService.logout();
                if (!res.success)
                    return;
                this.$router.push({
                    name: 'login',
                    params: { type: 'user' },
                });
            },
        });
    }
    modifyPassword() {
        this.isShowSetting = false;
        this.visibleUserInfo = false;
        this.showModifyPassword = true;
    }
};
__decorate([
    Prop()
], Setting.prototype, "sourcePageType", void 0);
__decorate([
    workPlatformModule.Mutation('updateIsSetOrg')
], Setting.prototype, "updateIsSetOrg", void 0);
Setting = __decorate([
    Component({
        name: 'Setting',
        components: {
            ModifyPassword: () => import('../modify-password'),
            Avatar: () => import('@/components/organization/org-manage/avatar.vue'),
        },
    })
], Setting);
export default Setting;
